import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <App />
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// ,
// 	document.getElementById('root')
// );

reportWebVitals();
