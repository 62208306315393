import {auth} from "./Firebase"
import firebase from "firebase/app"
import {firestore} from "./Firebase"
import { useAuth } from "../context/Context"
import axios from "axios"
import {Link,Redirect } from "react-router-dom"
import { useState } from "react"
import { CircularProgress } from "@material-ui/core"
import { FcGoogle } from "react-icons/fc";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const SignIn = ({googleSignInButtonBackgroundColor,referral,ctaText}) => {
	const [redirectToReferrer,setRedirectToReferrer] = useState("")
    const { login,facebookLogin } = useAuth()
	const [hideProgress,setHideProgress] = useState(true)
	const [hideProgressFacebook,setHideProgressFacebook] = useState(true)
	const [fbLogin,setfbLogin] = useState(false)
	const [openSnackbar, setOpenSnackbar] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState("")
	const [severityStatus, setSeverityStatus] = useState("")

	const captureFBAccessToken = async (token) => {
		console.log("captureFBAccessToken")

		return axios({
		 method:"post",
		 url:`/api/facebook/add/token`,
		 data:{
			 "user":firebase.auth().currentUser,
			 token:token
		 }
	   })
	   .then( res => {
		console.log(res.data)
		return res.data
	   })
	   .catch(err =>{
		   console.log(err)
	   })
	}

    const signInWithGoogle = async () => {
        const result = await login()
		// console.log(result)
		await setCustomClaims(result.user.getIdToken())
		auth.onAuthStateChanged(user => {
			if (user){
				user.getIdTokenResult()
				.then(idTokenResult =>{
					user.admin = idTokenResult.claims.admin
					// console.log(idTokenResult.claims.admin)
				})
			}
		  })
        userData()
    }

    const signInWithFacebook = async () => {
        const result = await facebookLogin()
		setfbLogin(true)
		const accessToken = result.credential.accessToken;
    	console.log(`User access token: ${accessToken}`);
		console.log(result)
		var accessTokenData = await captureFBAccessToken(accessToken)
		console.log("accessTokenData",accessTokenData)
		if(accessTokenData.existingUser){
			setRedirectToReferrer("fbPages")
		}else{
			setRedirectToReferrer("fbPages")
		}

		// send accesstoken to facebookPages selection
		setCustomClaims(result.user.getIdToken())
		auth.onAuthStateChanged(user => {
			if (user){
				user.getIdTokenResult()
				.then(idTokenResult =>{
					user.admin = idTokenResult.claims.admin
					console.log(idTokenResult.claims.admin)
				})
			}
		  })
    }

    

const setCustomClaims = async (idToken) => {
	let token = await idToken
	  axios({
		method:"post",
        url: "/api/setCustomClaims",
		headers: {
            withCredentials:true
        },
		data:{
			idToken:token,
		}
	  })
	  .then( res => {
		console.log(res)
		firebase.auth().currentUser.getIdToken(true);
	  })
	//   .then( (d,status) => {
	// 	console.log("d,status",d,status)

	// 	// if (status == 'success' && d) {
	// 	// 	const json = JSON.parse(d);
	// 	// 	if (json && json.status == 'success') {
	// 	// 	  // Force token refresh. The token claims will contain the additional claims.
	// 	// 	  firebase.auth().currentUser.getIdToken(true);
	// 	// 	}
	// 	//   }
	//   })
	  .catch(err => {
		  console.log(err)
	  })
}

const newContact = async () => {
	  return axios({
		method:"post",
        url: "/api/newContact/onSignUp",
		headers: {
            withCredentials:true
        },
		data:{
			"user":firebase.auth().currentUser
		}
	  })
	  .then( res => {
		var success = res.data
		setRedirectToReferrer("new")
	  })
	  .catch(function(error){
		console.log(error)
		setHideProgress(false)
		setOpenSnackbar(true)
		setSnackbarMessage("Error:Something went wrong. Please try again!")
		setSeverityStatus("error")
	})
}

const addToMailingList = async () => {
	axios.defaults.withCredentials = true
	return axios({
	method:"post",
	url:`/api/newsletter/waitingListFewo`,
	headers: {
		withCredentials:true
	},
	data:{
		user:firebase.auth().currentUser,
	}
	})
	.then( res => {
		console.log(res.data.id)
		setSnackbarMessage("Erfolgreich zur Warteliste hinzugefügt!")
		setSeverityStatus("success")
		setOpenSnackbar(true)
	})
	.catch(err =>{
		console.log(err)
	})
	}

    function userData() {
		//console.log(firebase.auth())
		var user = firebase.auth().currentUser
        firestore.collection("users").where("googleUserID", "==",`${user.uid}`).get().then(function(querySnapshot){
            let userData;
			querySnapshot.forEach(function(doc){
                        const data = doc.data()
						userData = data
                })
			if(referral === "waitlist"){
				setHideProgress(true)
				console.log("waitlist")
				addToMailingList()
				return
			}
			if (userData !== undefined){
				//getAccessToken(userData[user.email].refreshToken)
				// window.location.assign("/events")
				setRedirectToReferrer("returning")
				
			} else {
				newContact()
				// window.location.assign("/authCalendar")
			}
           
        })
        .catch(function(error){
            console.log(error)
        })
    }
	

	if (redirectToReferrer === "new" && fbLogin === false) {
		return (
		  <Redirect to={`/authCalendar${!!referral ? "?referral=" + referral : ""}`}/>
		)
	}
	if (redirectToReferrer == "returning") {
		return (
		  <Redirect to={"/events"}/>
		)
	}
	if (redirectToReferrer == "fbPages" && fbLogin) {
		return (
		  <Redirect to={"/facebookPages"}/>
		)
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}
	
	const dismissSnackbar = (event, reason) => {
	if (reason === 'clickaway') {
		return;
	}

	setOpenSnackbar(false);
	};


    return (
        <div
		style={{
			// width:"191px",
			// height:"46px",
		}}>
			<Snackbar 
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openSnackbar} autoHideDuration={10000} onClose={dismissSnackbar}>
				<Alert onClose={dismissSnackbar} severity={severityStatus}>
				{snackbarMessage}
				</Alert>
			</Snackbar>
			{hideProgress ? 
			<div
			className="signin-google-btn"
			onClick={async () => {
				setHideProgress(false)
				await signInWithGoogle()
				.catch((error) => {
					if (error.code === 'auth/popup-closed-by-user') {
					  // Handle the scenario where the user closed the popup without selecting an account
					  console.log('Popup closed by user without completing the sign-in.');
					  setHideProgress(true)
					} else {
					  // Handle other errors
					  console.error('Sign in error:', error);
					}
				})
			}}
			style={{
				
			}}>
				<div>
					<div>
					<FcGoogle /> {!!ctaText ? ctaText : "Sign in with Google"} 
					</div>
					
				</div>
				
			</div>
			 :
			<div>
				<button
				style={{
					width: "91%",
					height: "80%",
					margin: "0 9px",
					border:"0",
					borderRadius:"3px",
					backgroundColor:"white",
					
					

				}}>
					<CircularProgress
					size="1.5rem"
					color="white"
					sx={{
						fontSize:"16px"
					}} />
				</button>

			</div>}

			{false ? 
			<div
			className="signin-google-btn"
			style={{
				margin:"10px 0"
			}}
			onClick={() => {
				signInWithFacebook()
				setHideProgressFacebook(false)
			}}>
				<div>
					<div className="f"
					style={{justifyContent:"center"}}>
						<div
						style={{
							padding:"0 5px"
						}}>
							<img 
						src="https://upload.wikimedia.org/wikipedia/commons/0/05/Facebook_Logo_%282019%29.png"
						height="17px"
						width="17px"
						/>
						</div>
						<div>Sign in with Facebook</div>
					</div>
					
				</div>
				
			</div>
			 :
			<div>
				{/* <button
				style={{
					width: "91%",
					height: "80%",
					margin: "0 9px",
					border:"0",
					borderRadius:"3px",
					backgroundColor:"white",
					
					

				}}>
					<CircularProgress
					size="1.5rem"
					color="white"
					sx={{
						fontSize:"16px"
					}} />
				</button> */}
				{null}

			</div>}
        </div>
    )
}

export default SignIn
